import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/style.less'

Vue.config.productionTip = false

Vue.use(ElementUI, {
    size: 'small'
})

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
