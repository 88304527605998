export default [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Login - 登录',
            hideInMenu: true,
        },
        component: () => import('@/views/login')
    },
    {
        path: '/',
        name: '_home',
        redirect: '/device',
        component: () => import('@/views/main'),
        children: [
            {
                path: '/account',
                name: 'account',
                meta: {
                    title: '系统账号管理',
                    icon: 'el-icon-user',
                    hideInMenu: true
                },
                component: () => import('@/views/account')
            }, {
                path: '/company',
                name: 'company',
                meta: {
                    title: '公司管理',
                    icon: 'el-icon-office-building',
                    hideInMenu: true
                },
                component: () => import('@/views/company')
            }, {
                path: '/staff',
                name: 'staff',
                meta: {
                    title: '巡检员工管理',
                    icon: 'el-icon-s-custom',
                    hideInMenu: true
                },
                component: () => import('@/views/staff')
            }, {
                path: '/device',
                name: 'device',
                meta: {
                    title: '设备管理',
                    icon: 'el-icon-mobile',
                    hideInMenu: true
                },
                component: () => import('@/views/device'),
                children: [{
                    path: '/device/view/:id',
                    name: 'deviceView',
                    meta: {
                        title: '设备详情',
                        hideInMenu: true
                    },
                    component: () => import('@/views/device/view')
                }]
            }, {
                path: '/permission',
                name: 'permission',
                meta: {
                    title: '权限审核管理',
                    icon: 'el-icon-mobile',
                    hideInMenu: true
                },
                component: () => import('@/views/permission'),
                children: [{
                    path: '/permission/view/:id',
                    name: 'permissionView',
                    meta: {
                        title: '权限审核详情',
                        hideInMenu: true
                    },
                    component: () => import('@/views/permission/view')
                }]
            }, {
                path: '/user',
                name: 'user',
                meta: {
                    title: '修改密码',
                    icon: 'el-icon-mobile',
                    hideInMenu: true
                },
                component: () => import('@/views/user')
            }
        ]
    }
]
