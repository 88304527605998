import router from '@/router'
import routes from '@/router/routes'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
    NProgress.start()
    const arr = routes.filter(item => item.name === '_home')[0]['children']
    const params = {
        toPath: to.path,
        arr
    }
    store.dispatch('tagsView/getSysMenu', params).then(res => {
        console.log(res)
        NProgress.done()
        next()
    })
})
